<template>
  <el-form
    :model="configForm"
    :rules="rules"
    ref="configForm"
    label-width="100px"
    label-position="left"
  >
    <el-form-item label="SP" prop="Sp">
      <el-input
        v-model="configForm.Sp"
        placeholder="请输入SP,向易科士一起生活对接人获取"
        @change="GetCampus()"
      ></el-input>
    </el-form-item>

    <el-form-item label="SID" prop="Sid">
      <el-input
        v-model="configForm.Sid"
        placeholder="请输入Sid,向易科士一起生活对接人获取"
      ></el-input>
    </el-form-item>

    <el-form-item label="密钥" prop="ChargeSecretKey">
      <el-input
        v-model="configForm.ChargeSecretKey"
        placeholder="请输入ChargeSecretKey,向易科士一起生活对接人获取"
      ></el-input>
    </el-form-item>

    <el-form-item label="关联" prop="TeamId">
      <el-select
        v-model="TeamId_Name"
        placeholder="请选择一起生活关联组织或校区"
        @change="teamIdName()"
        filterable
      >
        <el-option
          v-for="ite in campus"
          :key="ite.teamId"
          :label="ite.teamName"
          :value="ite.teamId + '_' + ite.teamName"
        >
          <span style="float: left">{{ ite.teamName }}</span>
          <span
            style="float: right; color: var(--el-text-color-secondary); font-size: 13px"
            >{{ ite.teamId }}</span
          >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="编号" prop="TeamId" v-show="campus.length > 0">
      <el-input
        v-model="configForm.TeamId"
        placeholder="选择关联后自动填入编号"
        disabled="true"
      ></el-input>
    </el-form-item>

    <el-form-item label="名称" prop="TeamName" v-show="campus.length > 0">
      <el-input
        v-model="configForm.TeamName"
        placeholder="选择关联后自动填入名称"
        disabled="true"
      ></el-input>
    </el-form-item>

    <el-form-item label="屏蔽未办理" prop="ShieldingNotHandled">
      <el-switch
        v-model="configForm.ShieldingNotHandled"
        active-text="开"
        inactive-text="关"
      ></el-switch>
    </el-form-item>

    <el-form-item label="默认日限额" prop="DefaultDayMoney">
      <el-input
        v-model="configForm.DefaultDayMoney"
        placeholder="请输入默认日限额（例如:10.00 注意格式。）"
      ></el-input>
    </el-form-item>

    <el-form-item label="备注" prop="RemarkInfo">
      <el-input
        type="textarea"
        v-model="configForm.RemarkInfo"
        placeholder="请输入备注（长度不大于100个字符）"
      ></el-input>
    </el-form-item>
    <center>
      <el-button type="primary" @click="submitForm('configForm')">保存</el-button>
      <el-button @click="resetForm('configForm')">重置</el-button>
    </center>
  </el-form>
</template>

<script>
let _this;
export default {
  name: "saveYqshConfig",
  props: {
    configId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      configForm: {
        Id: 0,
        TeamId: "",
        TeamName: "",
        Sp: "",
        Sid: "",
        ChargeSecretKey: "",
        ShieldingNotHandled: false,
        DefaultDayMoney: "",
        RemarkInfo: "",
      },
      rules: {
        Sp: [
          {
            required: true,
            trigger: "blur",
            message: "请输入SP",
          },
        ],
        Sid: [
          {
            required: true,
            trigger: "blur",
            message: "请输入SID",
          },
        ],
        ChargeSecretKey: [
          {
            required: true,
            trigger: "blur",
            message: "请输入ChargeSecretKey",
          },
        ],
        TeamId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择关联",
          },
        ],
        TeamName: [
          {
            required: true,
            trigger: "blur",
            message: "请选择关联",
          },
        ],
        DefaultDayMoney: [
          {
            required: true,
            trigger: "blur",
            message: "请输入默认日限额（例如:100.05 注意格式。）",
          },
        ],
        RemarkInfo: [
          {
            min: 0,
            max: 100,
            message: "长度不大于100个字符",
            trigger: "blur",
          },
        ],
      },
      TeamId_Name: "",
      campus: [],
    };
  },
  created() {
    _this = this;
    if (_this.$props.configId != 0) {
      _this.$API.YqshConfig.QueryById.get(_this.$props.configId).then((res) => {
        _this.configForm = res;
        _this.GetCampus();
        _this.TeamId_Name = res.TeamId + "_" + res.TeamName;
      });
    }
  },
  methods: {
    GetCampus() {
      if (_this.configForm.Sp)
        _this.$API.YqshConfig.GetYqshCampus.get(_this.configForm.Sp).then((res) => {
          if (res.code) {
            _this.campus = res.value;
          } else {
            console.log(res.message);
          }
        });
    },
    teamIdName() {
      if (_this.TeamId_Name && this.TeamId_Name.indexOf("_") > 0) {
        _this.configForm.TeamId = _this.TeamId_Name.split("_")[0];
        _this.configForm.TeamName = _this.TeamId_Name.split("_")[1];
      }
    },
    submitForm(formName) {
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.$API.YqshConfig.AddOrUpdate.post(_this.configForm).then((res) => {
            if (res.Success) {
              _this.$message.success(res.Message);
              _this.$emit("operationSuccess");
            } else {
              _this.$message.error(res.Message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      _this.$refs[formName].resetFields();
    },
  },
};
</script>

<style></style>
